import React from "react";
import { NavLink, useMatch } from "react-router-dom";

import classes from "./NavItem.module.css";

const NavItem = (props) => {
  const { to, children, img, Icon } = props;
  const match = useMatch(to);

  return (
    <li className={classes.LinkItem}>
      <NavLink
        to={to}
        className={(props) => {
          return `${props.isActive ? classes.Active : ""} ${
            classes.LinkContainer
          }`;
        }}
      >
        <Icon color={match ? "#EC008C" : "#FFFFFF"} />
        <span className={classes.Title}>{children}</span>
      </NavLink>
    </li>
  );
};

export default NavItem;
