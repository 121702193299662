import React from "react";

const IconListen = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5147 11.6057H19.8378C19.4815 9.83627 18.5236 8.2448 17.127 7.10145C15.7305 5.95809 13.9812 5.33334 12.1762 5.33334C10.3713 5.33334 8.6221 5.95809 7.22551 7.10145C5.82892 8.2448 4.87109 9.83627 4.51473 11.6057H3.33341C2.45328 11.7311 1.64792 12.1698 1.06531 12.8413C0.482699 13.5128 0.161987 14.3721 0.161987 15.2611C0.161987 16.1501 0.482699 17.0093 1.06531 17.6808C1.64792 18.3523 2.45328 18.791 3.33341 18.9164H5.29012C5.53493 18.9164 5.76973 18.8192 5.94284 18.6461C6.11595 18.473 6.21319 18.2382 6.21319 17.9933V13.1564C6.21319 11.5733 6.84209 10.055 7.96154 8.93558C9.08099 7.81613 10.5993 7.18723 12.1824 7.18723C13.7655 7.18723 15.2839 7.81613 16.4033 8.93558C17.5228 10.055 18.1517 11.5733 18.1517 13.1564V17.9933C18.15 18.115 18.1727 18.2358 18.2185 18.3485C18.2643 18.4613 18.3323 18.5637 18.4184 18.6497C18.5044 18.7358 18.6068 18.8038 18.7195 18.8496C18.8323 18.8954 18.9531 18.9181 19.0748 18.9164H20.5147C20.7916 18.9164 20.9052 18.9523 21.4125 18.8028C21.9199 18.6533 22.3892 18.3966 22.7887 18.05C23.1881 17.7034 23.5085 17.275 23.728 16.7938C23.9476 16.3127 23.9997 15.8622 23.9997 15.3333C23.9997 14.8045 23.9476 14.2094 23.728 13.7282C23.5085 13.2471 23.1881 12.8187 22.7887 12.4721C22.3892 12.1255 21.9199 11.8688 21.4125 11.7193C20.9052 11.5698 20.6664 11.6057 20.5147 11.6057ZM2.02855 15.1192C2.02855 14.6956 2.24379 14.358 2.58309 14.0187C2.92238 13.6794 3.38254 13.4888 3.86238 13.4888H4.39168V17.1072H3.86238C3.61959 17.1106 3.37867 17.065 3.15385 16.9733C2.92903 16.8815 2.72499 16.7455 2.55386 16.5732C2.38274 16.4009 2.248 16.1959 2.15775 15.9705C2.06751 15.7451 2.02357 15.5038 2.02855 15.2611V15.1192ZM20.5147 17.1072H19.9978V13.4888H20.5147C20.9946 13.4888 21.4547 13.6794 21.794 14.0187C22.1333 14.358 22.3239 14.8181 22.3239 15.298C22.3239 15.7778 22.1333 16.238 21.794 16.5773C21.4547 16.9166 20.9946 17.1072 20.5147 17.1072Z"
      fill={props.color}
    />
  </svg>
);

export default IconListen;
