import { KEYS } from "../../util/localization/keys";
import { getI18n } from "react-i18next";

export const TERM_DATE = (year, quarter) => {
  const lang = getI18n().language;

  switch (quarter) {
    case 1:
      return `01.01.${year} - ${new Date(year, 3, 0).toLocaleDateString(lang)}`;
    case 2:
      return `01.04.${year} - ${new Date(year, 6, 0).toLocaleDateString(lang)}`;
    case 3:
      return `01.07.${year} - ${new Date(year, 9, 0).toLocaleDateString(lang)}`;
    case 4:
      return `01.10.${year} - ${new Date(year, 12, 0).toLocaleDateString(
        lang
      )}`;
    default:
      return;
  }
};

export const HEADERS = (isPodcast, isBookwire) =>
  isPodcast
    ? [
        KEYS.report_column_title_episode,
        KEYS.report_column_title_podcast,
        KEYS.report_column_title_publisher,
        KEYS.report_column_title_listeners,
        KEYS.report_column_title_duration,
      ]
    : [
        isBookwire ? KEYS.report_column_title_identifier : null,
        KEYS.pdf_title_report_content_title,
        KEYS.report_column_title_author,
        KEYS.pdf_title_report_publisher,
        !isBookwire ? KEYS.report_column_title_unit : null,
        isBookwire
          ? KEYS.report_column_title_listeners
          : KEYS.report_column_title_users,
        KEYS.report_column_title_duration,
        KEYS.report_column_title_income,
      ];
