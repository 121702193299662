import React from "react";
import Page from "../components/shared/UI/Page";

const Payment = (props) => {
  return (
    <Page>
      <h1>Payment</h1>
    </Page>
  );
};

export default Payment;
