import React, { useReducer, useRef, useState } from "react";
import { utils, writeFile } from "xlsx";

import Page from "../components/shared/UI/Page";
import Options from "../components/shared/UI/Options";
import SelectSection from "../components/Income/SelectSection";
import TableSection from "../components/Income/TableSection";

import { CURR_FORMAT, LOCALE_OPTIONS_0, LOCALE_OPTIONS_2 } from "../util/lib";
import { initialState, reducer } from "../components/Income/data";

import sharedClasses from "./shared.module.css";
import classes from "./Income.module.css";
import { useTranslation } from "react-i18next";
import { KEYS } from "../util/localization/keys";

const Income = (props) => {
  const { data, quarterlyData, currency, isPodcast, isBookwire } = props;
  const [subtotal, setSubtotal] = useState(0);
  const [{ term, year }, dispatch] = useReducer(reducer, initialState);
  const { t, i18n } = useTranslation();

  const excelTableRef = useRef(); //will be forwarded to table section

  const selectOptionsYear = data
    ? Object.keys(data)
        .map((val) => ({ text: val, value: +val }))
        .sort((a, b) => b.value - a.value)
    : [{ text: year, value: year }];

  const selectTermHandler = (e, section, value) => {
    dispatch({ type: section, payload: +value });
  };

  let subheader = "";
  if (term && quarterlyData) {
    const total = subtotal.toLocaleString(
      i18n.language,
      isPodcast ? LOCALE_OPTIONS_0 : LOCALE_OPTIONS_2
    );
    if (+term === 5) {
      const yearText = t(KEYS.report_n_year, {
        n: year,
      });
      subheader = isPodcast
        ? `${t(KEYS.report_n_hours, { n: total })} - ${yearText}`
        : `${total} ${CURR_FORMAT(currency)} - ${yearText}`;
    } else {
      const quarterText = t(KEYS.title_quarter, {
        n: term,
      });
      subheader = isPodcast
        ? `${t(KEYS.report_n_hours, { n: total })} - ${quarterText}`
        : `${total} ${CURR_FORMAT(currency)} - ${quarterText}`;
    }
  }

  const excelDownloadHandler = () => {
    let filename = "";
    if (+term === 5) {
      filename = isPodcast
        ? `-${t(KEYS.excel_filename_podcast_year, { n: year })}`
        : `-${t(KEYS.excel_filename_year, { n: year })}`;
    } else if (term) {
      filename = isPodcast
        ? `-${year}-${t(KEYS.excel_filename_podcast_quarter, { n: term })}`
        : `-${year}-${t(KEYS.excel_filename_quarter, { n: term })}`;
    }
    const worksheet = utils.table_to_sheet(excelTableRef.current, {
      cellStyles: true,
    });
    worksheet["!cols"] = [
      { width: 25 },
      { width: 25 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 18 },
    ];
    const workbook = utils.book_new();
    utils.book_append_sheet(
      workbook,
      worksheet,
      isPodcast
        ? t(KEYS.side_menu_title_podcast_report)
        : t(KEYS.side_menu_title_report)
    );
    writeFile(
      workbook,
      `Dinlebi${filename}-${isPodcast ? "" : CURR_FORMAT(currency)}.xlsx`
    );
  };

  return (
    <Page>
      <header className={classes.HeaderBox}>
        <h1 className={sharedClasses.Header}>
          {isPodcast
            ? t(KEYS.page_title_podcast_report)
            : t(KEYS.page_title_report)}
        </h1>
        <h2 className={sharedClasses.SubHeaderBlue}>{subheader}</h2>
      </header>

      <Options
        excelHandler={excelDownloadHandler}
        year={year}
        quarter={term}
        showExcelButton={true}
      />

      <SelectSection
        selectTermHandler={selectTermHandler}
        yearOptions={selectOptionsYear}
        yearValue={year}
        termValue={term}
      />

      <TableSection
        data={data}
        term={term}
        year={year}
        changeSubtotal={(val) => setSubtotal(val)}
        ref={excelTableRef}
        subtotal={subtotal}
        currency={currency}
        isPodcast={isPodcast}
        isBookwire={isBookwire}
      />
    </Page>
  );
};

export default Income;
