import { CURR_FORMAT, findQuarter } from "../../util/lib";
import { KEYS } from "../../util/localization/keys";

export const HEADERS = (isPodcast, isBookwire) =>
  isPodcast
    ? [
        KEYS.report_column_title_episode,
        KEYS.report_column_title_podcast,
        KEYS.report_column_title_publisher,
        KEYS.report_column_title_listeners,
        KEYS.report_column_title_duration,
      ]
    : [
        isBookwire ? KEYS.report_column_title_identifier : null,
        KEYS.report_column_title_name,
        KEYS.report_column_title_author,
        isBookwire ? KEYS.report_column_title_publishing_house : null,
        isBookwire ? KEYS.report_column_title_listeners : null,
        KEYS.report_column_title_duration,
        !isBookwire ? KEYS.report_column_title_unit : null,
        KEYS.report_column_title_income,
      ];

export const HEADERS_EXCEL = (isPodcast, curr, isBookwire) =>
  isPodcast
    ? [
        KEYS.report_column_title_episode,
        KEYS.report_column_title_podcast,
        KEYS.report_column_title_publisher,
        KEYS.report_column_title_listeners,
        KEYS.report_column_title_podcast_duration,
      ]
    : [
        isBookwire ? KEYS.report_column_title_identifier : null,
        KEYS.report_column_title_name,
        KEYS.report_column_title_author,
        KEYS.report_column_title_publishing_house,
        isBookwire
          ? KEYS.report_column_title_listeners
          : KEYS.report_column_title_users,
        KEYS.report_column_title_duration,
        !isBookwire ? KEYS.report_column_title_unit : null,
        KEYS.report_column_title_income,
      ];

export const SELECT_OPTIONS_QUARTERS = [
  {
    key: KEYS.select_box_default,
    keyValue: "",
    value: 0,
  },
  {
    key: KEYS.title_quarter,
    keyValue: "1",
    value: 1,
  },
  {
    key: KEYS.title_quarter,
    keyValue: "2",
    value: 2,
  },
  {
    key: KEYS.title_quarter,
    keyValue: "3",
    value: 3,
  },
  {
    key: KEYS.title_quarter,
    keyValue: "4",
    value: 4,
  },
  {
    key: KEYS.title_quarter_all,
    keyValue: "",
    value: 5,
  },
];

export const TERM = "TERM";
export const YEAR = "YEAR";

let prevQuarter = +findQuarter(new Date());
let selectedYear = +new Date().getFullYear();
if (prevQuarter === 1) {
  prevQuarter = 4;
  --selectedYear;
} else {
  --prevQuarter;
}

export const initialState = {
  term: prevQuarter,
  year: selectedYear,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case TERM:
      return { ...state, term: action.payload };
    case YEAR:
      return { ...state, year: action.payload, term: 0 };
    default:
      return state;
  }
};
