import axios from "axios";
import { getI18n } from "react-i18next";
import { KEYS } from "./localization/keys";

export const LOCALE_OPTIONS_0 = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};
export const LOCALE_OPTIONS_2 = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const CURR_FORMAT = (val) => {
  return !val
    ? ""
    : val.toLocaleUpperCase() === "TRY"
    ? "TL"
    : val.toLocaleUpperCase();
};

export const REGION_FORMAT = (val) => {
  return !val
    ? ""
    : val.toLocaleUpperCase() === "TRY"
    ? "TR"
    : val.toLocaleUpperCase();
};

export const setScreenSize = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

export const findQuarter = (date) => {
  const month = date.getMonth() + 1;
  return Math.ceil(month / 3);
};

export const getQuarterMonths = (quarter, year) => {
  const lang = getI18n().language;
  const months = [];
  for (let i = quarter * 3 - 2; i <= quarter * 3; i++) {
    let month = new Date(year, i - 1, 1).toLocaleDateString(lang, {
      month: "long",
      // year: "numeric",
    });
    months.push(month);
  }
  return months;
};

//calculate the last 4 quarters
export const getLast4Quarters = (date) => {
  const currYear = date.getFullYear();
  const prevYear = date.getFullYear() - 1;
  const currDate = date;

  const currQuarter = findQuarter(currDate);

  const last4Quarters = {
    [prevYear]: [],
    [currYear]: [],
  };

  for (let i = currQuarter; i > currQuarter - 4; i--) {
    if (i > 0) {
      last4Quarters[currYear].unshift(i);
    } else {
      last4Quarters[prevYear].unshift(i + 4);
    }
  }

  const quartersArr = [];
  //{months: (3) ['Nisan 2020', 'Mayıs 2020', 'Haziran 2020']
  //title: "2020 2. Çeyrek"}

  for (let year in last4Quarters) {
    for (let el of last4Quarters[year]) {
      quartersArr.push({
        // title: `${year} ${el}. Çeyrek`,
        title: `${year} ${getI18n().t(KEYS.title_quarter, { n: el })}`,
        months: getQuarterMonths(el, +year),
        year,
      });
    }
  }

  console.log(quartersArr);
  return quartersArr;
};

const getQuarterlyData = (data) => {
  const quarterlyData = {};
  const quarterSummary = {
    income: 0,
    duration: 0,
    users: 0,
  };

  if (!data) return { try: quarterSummary };

  const currQuarterMonthsArr = findActiveMonths(1); //1 quarter
  for (let curr in data) {
    const currSummary = { ...quarterSummary };
    for (let month of currQuarterMonthsArr) {
      if (!data[curr][month]) continue;
      const currData = data[curr][month];

      currSummary.income += +currData.filtered.income;
      currSummary.duration += +currData.filtered.duration;
      currSummary.users += +currData.filtered.users;

      quarterlyData[curr] = currSummary;
    }
  }

  return quarterlyData;
};

const getRenderedData = (monthlyData) => {
  const yearlyQuarterData = {};
  const montlyDataWTRKeys = {};

  for (let curr in monthlyData) {
    yearlyQuarterData[curr] = {};
    montlyDataWTRKeys[curr] = {};
    const currData = monthlyData[curr];

    for (let key in currData) {
      const year = new Date(key).getFullYear();
      const quarter = findQuarter(new Date(key));

      const lang = getI18n().language;

      const newTRKey = new Date(key).toLocaleString(lang, {
        month: "long",
        year: "numeric",
      });
      montlyDataWTRKeys[curr][newTRKey] = currData[key];

      const newData = currData[key]
        ? currData[key].data.map((obj) => ({ ...obj }))
        : [];

      if (!yearlyQuarterData[curr][year]) {
        yearlyQuarterData[curr][year] = {
          1: [],
          2: [],
          3: [],
          4: [],
          5: newData, //sum of quarters
          [quarter]: newData,
        };
      } else {
        const yearObj = yearlyQuarterData[curr][year];
        yearObj[quarter] = yearObj[quarter].concat(newData);
        yearObj[5] = yearObj[5].concat(newData); //sum of quarters
      }
    } //end of for statement
  }
  return {
    montlyDataWTRKeys,
    yearlyQuarterData,
  };
};

export const getMonthlyData = async (token, user) => {
  const url = `/api/getMonthlyData/${token}`;

  try {
    const response = await axios.get(url, {
      params: user
        ? {
            isBookwire: user.isBookwire ? 1 : 0,
            isPodcast: user.isPodcast ? 1 : 0,
          }
        : {},
    });
    const { error, code, monthlyData, total } = response.data;

    const renderedData = getRenderedData(monthlyData);
    const yearlyQuarterData = renderedData.yearlyQuarterData;
    const monthlyDataWTRKeys = renderedData.montlyDataWTRKeys;

    const quarterlyData = getQuarterlyData(monthlyDataWTRKeys);
    if (error) console.log(error, code);
    if (error) return null;
    return { monthlyDataWTRKeys, quarterlyData, total, yearlyQuarterData };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const findActiveMonths = (value) => {
  const DATE = new Date();
  let currQuarter = +findQuarter(DATE);
  let startYear = +DATE.getFullYear();
  if (currQuarter === 1) {
    --startYear;
    currQuarter = 4;
  } else {
    --currQuarter;
  }

  const monthsArr = [];

  let term = value; //son 3 ay = 1; son 6 ay = 2; son 9 ay = 3; son 12 ay = 4;

  for (let i = term, currYear = startYear; i > 0; i--) {
    const months = getQuarterMonths(currQuarter, currYear).map(
      (el) => `${el} ${currYear}`
    );

    monthsArr.unshift(...months);
    if (currQuarter - 1 < 1) {
      currQuarter = 4;
      currYear--;
    } else {
      currQuarter--;
    }
  }
  return monthsArr;
};

export const check = (condition, addedClass) => {
  return condition ? addedClass : "";
};

export const sumDataArr = (dataArr) => {
  const tableDataById = {};
  for (let item of dataArr) {
    if (!tableDataById[item._id]) {
      tableDataById[item._id] = {
        ...item,
        income: +item.income,
        duration: +item.duration,
        units: +item.units,
        users: +item.users,
      };
    } else {
      tableDataById[item._id].income += +item.income;
      tableDataById[item._id].duration += +item.duration;
      tableDataById[item._id].units += +item.units;
      tableDataById[item._id].users += +item.users;
    }
  }

  return Object.values(tableDataById);
};
