import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import Page from "../components/shared/UI/Page";
import SelectBox from "../components/shared/UI/SelectBox";
import Options from "../components/shared/UI/Options";
import Graph from "../components/Hours/Graph";

import sharedClasses from "./shared.module.css";
import classes from "./Hours.module.css";
import { findActiveMonths, findQuarter, LOCALE_OPTIONS_0 } from "../util/lib";
import { useTranslation } from "react-i18next";
import { KEYS } from "../util/localization/keys";

const Hours = (props) => {
  const { data, quarterlyData } = props;
  const [labels, setLabels] = useState([]);
  const { ref, inView } = useInView();
  const { t, i18n } = useTranslation();

  const selectTermHandler = (e, value) => {
    const term = value || e.target.value;
    const monthsArr = findActiveMonths(term);
    setLabels(monthsArr);
  };

  useEffect(() => {
    selectTermHandler(null, 1);
  }, []);

  let prevQuarter = findQuarter(new Date());
  prevQuarter = +prevQuarter === 1 ? 4 : +prevQuarter - 1;

  return (
    <Page>
      <header className={classes.HeaderBox}>
        <h1 className={sharedClasses.Header}>
          {t(KEYS.page_title_time_report)}
        </h1>
        <h2 className={sharedClasses.SubHeaderBlue}>
          {quarterlyData &&
            `${t(KEYS.report_n_hours, {
              n: quarterlyData.duration.toLocaleString(
                i18n.language,
                LOCALE_OPTIONS_0
              ),
            })} - ${t(KEYS.title_quarter, { n: prevQuarter })}`}
        </h2>
      </header>

      <Options />

      <section>
        <div className={classes.Select}>
          <SelectBox selectTermHandler={selectTermHandler} />
        </div>
      </section>
      <section ref={ref} className={classes.GraphSection}>
        {data && inView && Object.keys(data).length > 0 && (
          <Graph labels={labels} data={data || []} />
        )}
      </section>
    </Page>
  );
};

export default Hours;
