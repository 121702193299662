import React from "react";

const IconHome = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1201 9.05332L15.5067 1.46665C14.5652 0.532743 13.2928 0.00872803 11.9667 0.00872803C10.6406 0.00872803 9.36821 0.532743 8.42672 1.46665L0.840047 9.05332C0.279734 9.61703 -0.0363199 10.3786 -0.039917 11.1734V21C-0.039917 21.7957 0.276136 22.5588 0.838745 23.1214C1.40135 23.684 2.16443 24 2.96008 24H20.9601C21.7557 24 22.5187 23.684 23.0813 23.1214C23.6439 22.5588 23.9601 21.7957 23.9601 21V11.1734C23.9672 10.782 23.8966 10.3931 23.7524 10.0292C23.6082 9.66534 23.3933 9.3336 23.1201 9.05332ZM15 22H9.00004V18.0667C9.00004 17.271 9.31609 16.5079 9.8787 15.9453C10.4413 15.3827 11.2044 15.0667 12 15.0667C12.7957 15.0667 13.5588 15.3827 14.1214 15.9453C14.684 16.5079 15 17.271 15 18.0667V22ZM22 21C22 21.2652 21.8947 21.5196 21.7072 21.7071C21.5196 21.8947 21.2653 22 21 22H17V18.0667C17 16.7406 16.4733 15.4689 15.5356 14.5312C14.5979 13.5935 13.3261 13.0667 12 13.0667C10.674 13.0667 9.40216 13.5935 8.46448 14.5312C7.5268 15.4689 7.00004 16.7406 7.00004 18.0667V22H3.00004C2.7359 21.9966 2.48355 21.8901 2.29675 21.7033C2.10996 21.5165 2.00349 21.2642 2.00004 21V11.1734C2.00027 10.9082 2.10583 10.654 2.29342 10.4667L9.88009 2.87998C10.4443 2.32142 11.2061 2.00816 12 2.00816C12.794 2.00816 13.5559 2.32142 14.1201 2.87998L21.7067 10.4667C21.8919 10.6556 21.997 10.9088 22 11.1734V21Z"
      fill={props.color}
    />
  </svg>
);

export default IconHome;
