import React from "react";

import SelectBox from "../shared/UI/SelectBox";

import { SELECT_OPTIONS_QUARTERS, TERM, YEAR } from "./data";

import classes from "./SelectSection.module.css";

const SelectSection = (props) => {
  const { yearOptions, yearValue, termValue, selectTermHandler } = props;

  return (
    <section className={classes.SelectSection}>
      <div className={classes.Select}>
        <SelectBox
          selectTermHandler={(e) => selectTermHandler(e, YEAR, e.target.value)}
          value={yearValue}
          options={yearOptions}
        />
      </div>
      <div className={classes.Select}>
        <SelectBox
          selectTermHandler={(e) => selectTermHandler(e, TERM, e.target.value)}
          value={termValue}
          options={SELECT_OPTIONS_QUARTERS}
        />
      </div>
    </section>
  );
};
export default SelectSection;
