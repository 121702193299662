import { Suspense, useEffect, useState } from "react";

import { getMonthlyData, setScreenSize } from "./util/lib";
import { renderLoginRoutes, renderRoutes, renderSideBar } from "./util/routes";

import { AuthContext } from "./util/context";
import useAuth from "./util/hooks/auth-hook";

import "./App.css";
import ErrorBoundary from "./components/shared/Error/ErrorBoundary";
import CustomPage from "./util/CustomPage";

// eslint-disable-next-line no-unused-vars
import localization from "./util/localization";

function App() {
  const { token, user, login, logout, autoLogin, isLoading } = useAuth();

  const [isLoaded, setIsLoaded] = useState(false);
  const [analysis, setAnalysis] = useState({
    monthlyData: null,
    quarterlyData: null,
    totalData: null,
    yearlyQuarterData: null,
    currency: "",
  });

  const currOptions = () => {
    if (analysis.monthlyData) {
      const options = Object.keys(analysis.monthlyData);
      if (options.length > 1) return options;
    }

    return [];
  };

  const currChangeHandler = (e) => {
    const value = e.target.value;
    setAnalysis((prev) => ({
      ...prev,
      currency: value,
    }));
    localStorage.setItem("dinlebi-gelir_curr", value);
  };

  const setCurrency = (data) => {
    const storedCurr = localStorage.getItem("dinlebi-gelir_curr");
    if (storedCurr && data[storedCurr]) return storedCurr;

    const currKeys = data ? Object.keys(data) : [];

    return currKeys[0];
  };

  useEffect(() => {
    setScreenSize();
    window.addEventListener("resize", setScreenSize);
    return () => window.removeEventListener("resize", setScreenSize);
  }, []);

  useEffect(() => {
    if (token) return;

    const checkUser = async () => {
      try {
        const response = await autoLogin();
        const { error } = response;
        if (error) console.log(error);
        setIsLoaded(true);
      } catch (err) {
        console.log(err);
        setIsLoaded(true);
      }
    };

    checkUser();
  }, [autoLogin, token]);

  useEffect(() => {
    const getData = async () => {
      const res = await getMonthlyData(token, user);
      if (res) {
        const {
          monthlyDataWTRKeys: monthlyData,
          quarterlyData,
          total: totalData,
          yearlyQuarterData,
        } = res;

        setAnalysis({
          currency: setCurrency(monthlyData),
          monthlyData,
          quarterlyData,
          totalData,
          yearlyQuarterData,
        });
      }
    };

    if (!token || !user) {
      setAnalysis((prev) => ({
        currency: "",
        monthlyData: null,
        quarterlyData: null,
        totalData: null,
        yearlyQuarterData: null,
      }));
    } else {
      getData();
    }
  }, [token, user]);

  const routes = !isLoaded
    ? null
    : !!token
    ? renderRoutes(analysis, !!user?.isPodcast, !!user?.isBookwire)
    : renderLoginRoutes();

  const value = {
    token,
    user,
    login,
    autoLogin,
    logout,
    isLoading,
  };

  const sideBarProps = {
    logo: user?.logo || null,
    options: currOptions(),
    value: analysis.currency,
    changeHandler: currChangeHandler,
    isPodcast: !!user?.isPodcast,
  };

  return (
    <Suspense fallback={() => <p>Loading...</p>}>
      <ErrorBoundary>
        <AuthContext.Provider value={value}>
          <>
            <div className="app">
              <aside>{renderSideBar(sideBarProps)}</aside>
              <CustomPage user={user}>{routes}</CustomPage>
            </div>
          </>
        </AuthContext.Provider>
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
