export const KEYS = {
  publishers_login_header: "publishers_login_header",
  publishers_login_email: "publishers_login_email",
  publishers_login_password: "publishers_login_password",
  publishers_login_button: "publishers_login_button",
  publishers_error_missing: "publishers_error_missing",
  publishers_error_email: "publishers_error_email",
  publishers_error_password: "publishers_error_password",
  publishers_error_server: "publishers_error_server",
  page_title_dashboard: "publishers_page_title_dashboard",
  description_overview: "publishers_description_overview",
  title_total_income: "publishers_title_total_income",
  title_total_hours: "publishers_title_total_hours",
  title_n_hours: "publishers_title_n_hours",
  title_this_quarter: "publishers_title_this_quarter",
  title_n_listener: "publishers_title_n_listener",
  title_total_listeners: "publishers_title_total_listeners",
  button_title_logout: "publishers_button_title_logout",
  title_portal: "publishers_title_portal",
  title_last_n_month: "publishers_title_last_n_month",
  title_last_n_year: "publishers_title_last_n_year",
  page_title_time_report: "publishers_page_title_time_report",
  title_quarter: "publishers_title_quarter",
  button_title_excel_download: "publishers_button_title_excel_download",
  button_title_pdf_download: "publishers_button_title_pdf_download",
  report_column_title_author: "publishers_report_column_title_author",
  report_column_title_duration: "publishers_report_column_title_duration",
  report_column_title_unit: "publishers_report_column_title_unit",
  report_column_title_identifier: "publishers_report_column_title_identifier",
  report_column_title_income: "publishers_report_column_title_income",
  side_menu_title_report: "publishers_side_menu_title_report",
  side_menu_title_overview: "publishers_side_menu_title_overview",
  side_menu_title_time_report: "publishers_side_menu_title_time_report",
  page_title_report: "publishers_page_title_report",
  pdf_title_report: "publishers_pdf_title_report",
  pdf_title_publisher_name: "publishers_pdf_title_publisher_name",
  pdf_title_report_period: "publishers_pdf_title_report_period",
  pdf_title_report_date: "publishers_pdf_title_report_date",
  pdf_title_report_publisher: "publishers_pdf_title_report_publisher",
  pdf_title_report_content_title: "publishers_pdf_title_report_content_title",
  webpage_title: "publishers_webpage_title",
  tooltip_guide: "publishers_tooltip_guide",
  select_box_default: "publishers_select_box_default",
  title_n_income: "publishers_title_n_income",
  side_menu_title_podcast_report: "publishers_side_menu_title_podcast_report",
  page_title_podcast_report: "publishers_page_title_podcast_report",
  report_column_title_episode: "publishers_report_column_title_episode",
  report_column_title_podcast: "publishers_report_column_title_podcast",
  report_column_title_publisher: "publishers_report_column_title_publisher",
  report_column_title_listeners: "publishers_report_column_title_listeners",
  report_column_title_name: "publishers_report_column_title_name",
  report_column_title_publishing_house:
    "publishers_report_column_title_publishing_house",
  report_column_title_podcast_duration:
    "publishers_report_column_title_podcast_duration",
  report_column_title_users: "publishers_report_column_title_users",
  title_quarter_all: "publishers_title_quarter_all",
  sum_title_podcast_year: "publishers_sum_title_podcast_year",
  sum_title_year: "publishers_sum_title_year",
  sum_title_quarter: "publishers_sum_title_quarter",
  sum_title_podcast_quarter: "publishers_sum_title_podcast_quarter",
  report_term_year: "publishers_report_term_year",
  report_no_data: "publishers_report_no_data",
  report_n_hours: "publishers_report_n_hours",
  report_n_listener: "publishers_report_n_listener",
  report_n_unit: "publishers_report_n_unit",
  report_n_year: "publishers_report_n_year",
  excel_filename_year: "publishers_excel_filename_year",
  excel_filename_podcast_quarter: "publishers_excel_filename_podcast_quarter",
  excel_filename_quarter: "publishers_excel_filename_quarter",
  excel_filename_podcast_year: "publishers_excel_filename_podcast_year",
  tooltip_error_quarter: "publishers_select_box_default"
};
