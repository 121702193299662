import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { KEYS } from "./localization/keys";

const CustomHelmet = (props) => {
  const { t, i18n } = useTranslation();
  const { user } = props;

  useEffect(() => {
    if (!user) return;

    if (user && i18n) {
      const lang = user.isBookwire ? "en" : "tr";

      if (lang !== i18n.language) {
        i18n.changeLanguage(lang);
        window.location.reload();
      }
    }
  }, [i18n, user]);

  return (
    <>
      <Helmet>
        <title>{t(KEYS.webpage_title)}</title>
        <meta name="description" content={t(KEYS.webpage_title)} />
        <meta
          property="og:title"
          content={t(KEYS.webpage_title)}
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content={t(KEYS.webpage_title)}
          data-react-helmet="true"
        />
      </Helmet>
      {props.children}
    </>
  );
};

export default CustomHelmet;
