import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { CountUp } from "use-count-up";
import { CURR_FORMAT } from "../../util/lib";
import { KEYS } from "../../util/localization/keys";

import classes from "./Stats.module.css";

const Stats = (props) => {
  const {
    data: { duration: durationQ = 0, income: incomeQ = 0, users: usersQ = 0 },
    totalData: {
      duration: durationT = 0,
      income: incomeT = 0,
      users: usersT = 0,
    },
    currency,
    isPodcast,
  } = props;
  const { t, i18n } = useTranslation();

  const BOX_DATA = [
    {
      show: !isPodcast,
      title: t(KEYS.title_total_income),
      totalValue: incomeT || 0,
      quarterValue: incomeQ || 0,
      unit: CURR_FORMAT(currency),
      unitKey: KEYS.title_n_income,
      style: {
        item: { boxShadow: "0px 6px 22px rgba(236, 0, 140, 0.16)" },
        total: { color: "#EC008C" },
      },
    },
    {
      show: true,
      title: t(KEYS.title_total_hours),
      totalValue: durationT,
      quarterValue: durationQ,
      unitKey: KEYS.title_n_hours,
      style: {
        item: { boxShadow: "0px 6px 22px rgba(0, 174, 239, 0.16)" },
        total: { color: "#00AEEF" },
      },
    },
    {
      show: true,
      title: t(KEYS.title_total_listeners),
      totalValue: usersT,
      quarterValue: usersQ,
      unitKey: KEYS.title_n_listener,
      style: {
        item: { boxShadow: "0px 6px 22px rgba(46, 49, 146, 0.16)" },
        total: { color: "#2E3192" },
      },
    },
  ];

  const options = { minimumFractionDigits: 0, maximumFractionDigits: 0 };
  const formatter = (value) => value.toLocaleString(i18n.language, options);

  const items = BOX_DATA.filter((el) => el.show).map((item) => {
    return (
      <div key={item.title} className={classes.Item} style={item.style.item}>
        <p className={classes.Title}>{item.title}</p>
        <p className={classes.Total} style={item.style.total}>
          <Trans
            i18nKey={item.unitKey}
            values={item.unit ? { unit: item.unit } : { unit: "" }}
            components={{
              n: (
                <CountUp
                  isCounting={true}
                  start={0}
                  end={item.totalValue}
                  duration={2}
                  thousandsSeparator="."
                  easing="linear"
                  formatter={formatter}
                />
              ),
            }}
          />
        </p>
        <p className={classes.Quarter}>
          {`${t(KEYS.title_this_quarter)}: `}
          <Trans
            i18nKey={item.unitKey}
            values={item.unit ? { unit: item.unit } : { unit: "" }}
            components={{
              n: (
                <CountUp
                  isCounting={true}
                  start={0}
                  end={item.quarterValue}
                  duration={2}
                  thousandsSeparator="."
                  easing="linear"
                  formatter={formatter}
                />
              ),
            }}
          />
        </p>
      </div>
    );
  });

  return <div className={classes.Container}>{props.data && items}</div>;
};

export default Stats;
