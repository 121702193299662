import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { KEYS } from "../../util/localization/keys";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip
);

const options = (t, lang) => ({
  animations: {
    easing: "linear",
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      usePointStyle: true,
      callbacks: {
        label: (context) => {
          let label = context.dataset.label || "";

          if (context.parsed.y !== null) {
            const value = context.parsed.y.toLocaleString(lang);
            label += t(KEYS.report_n_hours, { n: value });
          }
          return label;
        },
        labelPointStyle: function (context) {
          return {
            pointStyle: "circle",
            borderWidth: 3,
            radius: 11.5,
            borderColor: "#00AEEF",
            backgroundColor: "white",
            hoverRadius: 11.5,
            hoverBorderWidth: 3,
            pointHoverBackgroundColor: "#00AEEF",
          };
        },
      },
    },
  },
  scales: {
    y: {
      min: 0,
      ticks: {
        // stepSize: 500,
        callback: (val) => {
          const value = val.toLocaleString(lang);
          return t(KEYS.report_n_hours, { n: value });
        },
        font: {
          family: "Panton",
          size: 14,
          lineHeight: 1.2,
        },
        color: "#000000",
      },
      grid: {
        color: "rgba(39, 40, 57, 0.3)",
        tickColor: "transparent",
        borderDash: [5],
        borderColor: "transparent",
      },
    },
    x: {
      offset: true,
      ticks: {
        font: {
          family: "Panton",
          size: 14,
          lineHeight: 1.2,
        },
        color: "rgba(11, 11, 18, 0.5)",
      },
      grid: {
        color: "transparent",
      },
    },
  },
  elements: {
    point: {
      pointStyle: "circle",
      borderWidth: 3,
      radius: 11.5,
      borderColor: "#00AEEF",
      backgroundColor: "white",
      hoverRadius: 11.5,
      hoverBorderWidth: 3,
      pointHoverBackgroundColor: "#00AEEF",
    },
    line: {
      borderColor: "#272839",
      backgroundColor: "white",
      borderWidth: 3,
    },
  },
});

const Graph = (props) => {
  const { labels, data: monthlyData } = props;
  const { t, i18n } = useTranslation();

  const pointImg = new Image();
  pointImg.src = "/ellipse.png";

  const dataArr = [];
  for (let el of labels) {
    if (monthlyData[el]) {
      dataArr.push(monthlyData[el].filtered.duration);
    } else {
      dataArr.push(0);
    }
  }

  const data = {
    labels,
    datasets: [
      {
        data: dataArr,
        maxBarThickness: 116,
      },
    ],
  };
  return <Line options={options(t, i18n.language)} data={data} />;
};

export default Graph;
