import React from "react";
import { useTranslation } from "react-i18next";

import classes from "./TranslateBox.module.css";

const TranslateBox = (props) => {
  const { i18n } = useTranslation();

  const changeLanguageHandler = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  const containerClass = [
    classes.InputArea,
    props.className ? props.className : "",
    props.dark ? classes.Dark : "",
  ].join(" ");

  return (
    <div style={props.style} className={containerClass}>
      <select
        defaultValue={i18n.language}
        onChange={changeLanguageHandler}
        className={classes.SelectBox}
      >
        <option value="tr">TR</option>
        <option value="en">EN</option>
        <option value="de">DE</option>
      </select>
    </div>
  );
};

export default TranslateBox;
