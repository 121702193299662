import { t } from "i18next";
import React from "react";
import { KEYS } from "../../../util/localization/keys";

import classes from "./SelectBox.module.css";

const SelectBox = (props) => {
  const options = props.options || [
    {
      key: KEYS.title_last_n_month,
      keyValue: "3",
      value: 1, // 1 quarter
    },
    {
      key: KEYS.title_last_n_month,
      keyValue: "6",
      value: 2, // 2 quarters
    },
    {
      key: KEYS.title_last_n_month,
      keyValue: "9",
      value: 3,
    },
    {
      key: KEYS.title_last_n_year,
      keyValue: "1",
      value: 4,
    },
  ];

  const selectObj =
    props.value || props.value === 0
      ? {
          value: props.value,
          onChange: props.selectTermHandler,
          className: classes.Select,
        }
      : {
          onChange: props.selectTermHandler,
          className: classes.Select,
        };

  const wrapperClass = [
    classes.Wrapper,
    props.isLight ? classes.Light : "",
  ].join(" ");

  return (
    <span className={wrapperClass}>
      <select {...selectObj}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.key
              ? t(option.key, {
                  n: option.keyValue,
                })
              : option.text}
          </option>
        ))}
      </select>
    </span>
  );
};

export default SelectBox;
