import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import {
  CURR_FORMAT,
  LOCALE_OPTIONS_0,
  LOCALE_OPTIONS_2,
} from "../../util/lib";
import { getI18n } from "react-i18next";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

export const options = (curr) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      callbacks: {
        label: (context) => {
          let label = context.dataset.label || "";

          if (context.parsed.y !== null) {
            label +=
              context.parsed.y.toLocaleString(
                getI18n().language,
                LOCALE_OPTIONS_2
              ) +
              " " +
              CURR_FORMAT(curr);
          }
          return label;
        },
      },
    },
  },
  elements: {
    bar: {
      borderRadius: 10,
    },
  },
  scales: {
    y: {
      ticks: {
        // stepSize: 1000,
        callback: (val) =>
          val.toLocaleString(getI18n().language, LOCALE_OPTIONS_0) +
          " " +
          CURR_FORMAT(curr),
        font: {
          family: "Panton",
          size: 14,
          lineHeight: 1.2,
        },
        color: "#000000",
      },
      grid: {
        color: "rgba(39, 40, 57, 0.3)",
        tickColor: "transparent",
        borderDash: [5],
        borderColor: "transparent",
      },
    },
    x: {
      ticks: {
        font: {
          family: "Panton",
          size: 14,
          lineHeight: 1.2,
        },
        color: "rgba(11, 11, 18, 0.5)",
      },
      grid: {
        color: "transparent",
      },
    },
  },
});

const Graph = (props) => {
  const { labels, data: monthlyData, currency } = props;

  const dataArr = [];
  for (let el of labels) {
    if (monthlyData[el]) {
      dataArr.push(monthlyData[el].filtered.income);
    } else {
      dataArr.push(0);
    }
  }

  const data = {
    labels,
    datasets: [
      {
        backgroundColor: "#272839",
        maxBarThickness: 116,
        data: dataArr,
      },
    ],
  };

  return <Bar options={options(currency)} data={data} />;
};

export default Graph;
