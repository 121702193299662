import { Routes, Route, Navigate } from "react-router-dom";

import Navbar from "../components/shared/Navigation/Navbar";
import Dashboard from "../pages/Dashboard";
import Hours from "../pages/Hours";
import Income from "../pages/Income";
import Login from "../pages/Login";
import Payment from "../pages/Payment";
import Report from "../pages/Report";

export const renderRoutes = (analysisObj, isPodcast, isBookwire) => {
  const { monthlyData, quarterlyData, totalData, yearlyQuarterData, currency } =
    analysisObj;
  const dataObj = {
    data: monthlyData ? monthlyData[currency] : null,
    totalData: totalData ? totalData[currency] : null,
    quarterlyData: quarterlyData ? quarterlyData[currency] : null,
    currency: isBookwire ? "eur" : currency,
    isPodcast,
    isBookwire,
  };
  const reportObj = yearlyQuarterData ? yearlyQuarterData[currency] : null;

  return (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <main>
            <Dashboard {...dataObj} />{" "}
          </main>
        }
      />
      <Route
        path="/hours"
        element={
          <main>
            <Hours {...dataObj} />
          </main>
        }
      />
      <Route
        path="/income"
        element={
          <main>
            <Income
              data={reportObj}
              quarterlyData={quarterlyData}
              currency={dataObj.currency}
              isPodcast={isPodcast}
              isBookwire={isBookwire}
            />
          </main>
        }
      />
      <Route
        path="/payment"
        element={
          <main>
            <Payment data={monthlyData} totalData={totalData} />
          </main>
        }
      />
      <Route
        path="/report"
        element={
          <main className="full-width white">
            <Report
              data={reportObj}
              currency={dataObj.currency}
              isPodcast={isPodcast}
              isBookwire={isBookwire}
            />
          </main>
        }
      />
      <Route path="*" element={<Navigate to={`/dashboard`} />} />
    </Routes>
  );
};

export const renderLoginRoutes = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <main className="full-width">
            <Login />
          </main>
        }
      />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
export const renderSideBar = (props) => {
  const element = (
    <Navbar
      logo={props.logo}
      options={props.options}
      changeHandler={props.changeHandler}
      value={props.value}
      isPodcast={props.isPodcast}
    />
  );
  return (
    <Routes>
      <Route path="/login" element={null} />
      <Route path="/report" element={null} />
      <Route path="/dashboard" element={element} />
      <Route path="/hours" element={element} />
      <Route path="/income" element={element} />
      <Route path="/payment" element={element} />
    </Routes>
  );
};
