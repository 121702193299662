import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./index.css";

import App from "./App";

const element = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
const root = document.getElementById("root");

ReactDOM.render(element, root);
