import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../util/context";
import { KEYS } from "../../util/localization/keys";

import classes from "./LoginForm.module.css";

const notify = (message, duration) =>
  toast.error(message, {
    duration: duration || 2000,
    position: "top-center",
    className: classes.Toast,
  });

const LoginForm = (props) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const checkValidity = (email, password) => {
    if (!email || !password) {
      notify(t(KEYS.publishers_error_missing));
      return false;
    }

    const pattern = /^\S+@\S+\.\S+$/;
    const isEmailValid = pattern.test(email);
    if (!isEmailValid) {
      notify(t(KEYS.publishers_error_email));
      return false;
    }

    const isPasswordValid = password.length >= 6;
    if (!isPasswordValid) {
      notify(t(KEYS.publishers_error_password));
      return false;
    }

    return true;
  };

  const loginHandler = async (e) => {
    e.preventDefault();

    const email = e.target.email.value;
    const password = e.target.password.value;

    const isValidEntry = checkValidity(email, password);

    if (!isValidEntry) return;
    else {
      try {
        const response = await auth.login(email, password);
        const { token, user, error } = response;
        if (error) {
          notify(error, 4000);
        } else if (token && user) {
          const lngQuery = user.isBookwire ? "?lng=en" : "?lng=tr";
          navigate(`/dashboard`, { replace: true });
          // window.location.reload(false);
        }
      } catch (err) {
        notify(t(KEYS.publishers_error_server), 4000);
      }
    }
  };

  return (
    <div className={classes.FormContainer}>
      <h1>{t(KEYS.publishers_login_header)}</h1>
      <form onSubmit={loginHandler}>
        <div className={classes.InputContainer}>
          <label id="email">{t(KEYS.publishers_login_email)}</label>
          <input
            type="email"
            name="email"
            placeholder={t(KEYS.publishers_login_email)}
          />
        </div>
        <div className={classes.InputContainer}>
          <label id="password">{t(KEYS.publishers_login_password)}</label>
          <input
            type="password"
            name="password"
            placeholder={t(KEYS.publishers_login_password)}
          />
        </div>
        <button
          className={classes.Button}
          type="submit"
          disabled={auth.isLoading}
        >
          {t(KEYS.publishers_login_button)}
        </button>
      </form>
      <Toaster />
    </div>
  );
};

export default LoginForm;
