import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../util/context";
import {
  CURR_FORMAT,
  LOCALE_OPTIONS_0,
  LOCALE_OPTIONS_2,
  sumDataArr,
} from "../../util/lib";
import { KEYS } from "../../util/localization/keys";
import { HEADERS } from "./data";

import classes from "./TableSection.module.css";

const TableSection = (props) => {
  const { data, year, quarter, currency, isPodcast, isBookwire } = props;
  const context = useContext(AuthContext);
  const { user } = context;
  const headersArr = HEADERS(isPodcast, isBookwire).filter((el) => el);
  const { t, i18n } = useTranslation();

  const tableData =
    data[year] && data[year][quarter]
      ? sumDataArr(data[year][quarter]).sort((a, b) => b.income - a.income)
      : [];

  const sum = {
    value: isPodcast
      ? tableData
          .reduce((acc, cur) => acc + cur.duration, 0)
          .toLocaleString(i18n.language, LOCALE_OPTIONS_0)
      : tableData
          .reduce((acc, cur) => acc + cur.income, 0)
          .toLocaleString(i18n.language, LOCALE_OPTIONS_2),
  };

  const headerItems = headersArr.map((item) =>
    item ? <th key={item}>{t(item)}</th> : null
  );

  let rowItems = null;

  if (!tableData || tableData.length === 0) {
    rowItems = (
      <tr>
        <td className={classes.NoData} colSpan={headersArr.length}>
          {t(KEYS.report_no_data)}
        </td>
      </tr>
    );
  } else {
    rowItems = tableData.map((items) => {
      const units = items.units.toLocaleString(i18n.language, LOCALE_OPTIONS_0);
      const duration = items.duration.toLocaleString(
        i18n.language,
        LOCALE_OPTIONS_0
      );
      const income = `${CURR_FORMAT(currency)} ${items.income.toLocaleString(
        i18n.language,
        LOCALE_OPTIONS_2
      )}`;
      const isNewType = isBookwire || isPodcast;

      return (
        <tr className={classes.TableRow} key={items._id}>
          {isBookwire && <td>{items.identifier}</td>}
          <td>{items.title}</td>
          {isPodcast ? <td>{items.podcast}</td> : <td>{items.author}</td>}
          {!isNewType ? (
            <td>{user && user.name}</td>
          ) : (
            <td>{items.publisher}</td>
          )}
          {!isNewType && <td>{units}</td>}
          <td>{items.users}</td>
          <td>{duration}</td>
          {!isPodcast && <td className={classes.NoWrap}>{income}</td>}
        </tr>
      );
    });
  }

  let sumText = "";
  if (+quarter === 5)
    sumText = t(isPodcast ? KEYS.sum_title_podcast_year : KEYS.sum_title_year, {
      n: year,
    });
  else
    sumText = t(
      isPodcast ? KEYS.sum_title_podcast_quarter : KEYS.sum_title_quarter,
      { n: quarter }
    );

  return (
    <section>
      <table className={classes.Table}>
        <thead>
          <tr className={classes.TableHead}>{headerItems}</tr>
        </thead>
        <tbody>{rowItems}</tbody>
        <tfoot>
          <tr className={classes.Footer}>
            <th scope="row" colSpan={headersArr.length - 1} align="left">
              {quarter && sumText}
            </th>
            <td className={classes.NoWrap}>
              {isPodcast
                ? t(KEYS.report_n_hours, { n: sum.value })
                : `${sum.value} ${CURR_FORMAT(currency)}`}
            </td>
          </tr>
        </tfoot>
      </table>
    </section>
  );
};
export default TableSection;
