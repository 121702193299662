import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../util/context";
import { KEYS } from "../../util/localization/keys";
import { TERM_DATE } from "./data";

import classes from "./ReportInfoSection.module.css";

const ReportInfoSection = (props) => {
  const { year, quarter } = props;
  const context = useContext(AuthContext);
  const { user } = context;
  const { t, i18n } = useTranslation();

  const reportTerm =
    +quarter === 5
      ? `${t(KEYS.report_term_year, { n: year })}`
      : `${TERM_DATE(year, +quarter)} (${t(KEYS.title_quarter, {
          n: quarter,
        })})`;

  return (
    <section className={classes.Container}>
      <ul>
        <li>
          <strong>{t(KEYS.pdf_title_publisher_name)}:</strong>{" "}
          {user && user.name}
        </li>
        <li>
          <strong>{t(KEYS.pdf_title_report_period)}:</strong> {reportTerm}
        </li>
        <li>
          <strong>{t(KEYS.pdf_title_report_date)}:</strong>{" "}
          {new Date().toLocaleDateString(i18n.language)}
        </li>
      </ul>
    </section>
  );
};

export default ReportInfoSection;
