import { useState, useCallback } from "react";
import axios from "axios";

const useAuth = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState();
  const [user, setUser] = useState();

  const logout = useCallback(() => {
    console.log("logout");
    setToken(null);
    setUser(null);
    localStorage.removeItem("dinlebi-gelir");
  }, []);

  const login = useCallback(
    async (email, password) => {
      setIsLoading(true);
      if (token) logout();

      let url = "/api/login";
      let data = { email, password };
      let errorMessage = null;
      try {
        let response = await axios.post(url, data);
        setIsLoading(false);
        let { token, user, error, code } = response.data;

        if (error) {
          errorMessage = error.includes("Invalid credentials")
            ? "Hatalı giriş, lütfen şifreni kontrol et."
            : `${error} Kod: ${code}`;

          logout();
        } else {
          setToken(token);
          setUser(user);
          const data = { token };
          localStorage.setItem("dinlebi-gelir", JSON.stringify(data));
          return { token, user };
        }
      } catch (err) {
        setIsLoading(false);
        errorMessage = err.message || "Hatalı işlem!";
      }

      return { error: errorMessage };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logout, token]
  );

  const autoLogin = useCallback(async () => {
    const storedData = JSON.parse(localStorage.getItem("dinlebi-gelir"));
    if (!storedData || !storedData.token)
      return { error: "Kullanıcı bilgileri bulunamadı." };

    setIsLoading(true);
    let url = `/api/autoLogin/${storedData.token}`;
    let errorMessage = null;
    try {
      let response = await axios.get(url);
      let { user, error, code } = response.data;
      setIsLoading(false);

      if (error) {
        errorMessage = `${error} Kod: ${code}`;
        logout();
      } else {
        setToken(storedData.token);
        setUser(user);
        return { token: storedData.token, user };
      }
    } catch (err) {
      setIsLoading(false);
      errorMessage = err.message || "Hatalı işlem!";
      logout();
    }

    return { error: errorMessage };
  }, [logout]);

  return { token, user, login, logout, autoLogin, isLoading };
};

export default useAuth;
