import React from "react";

import LoginForm from "../components/Login/LoginForm";
import TranslateBox from "../util/localization/TranslateBox";

import logo from "../assets/svg/dinlebi-logo.svg";

import classes from "./Login.module.css";

const Login = (props) => {
  return (
    <div className={classes.Container}>
      <header className={classes.HeaderContainer}>
        <a href="https://dinlebi.io/">
          <img src={logo} alt="dinlebi-logo" />
        </a>
        <TranslateBox />
      </header>

      <section className={classes.LoginSection}>
        <LoginForm />
      </section>
    </div>
  );
};

export default Login;
