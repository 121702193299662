import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../util/context";

import {
  CURR_FORMAT,
  LOCALE_OPTIONS_0,
  LOCALE_OPTIONS_2,
  sumDataArr,
} from "../../util/lib";
import { KEYS } from "../../util/localization/keys";
import { TERM_DATE } from "../Report/data";
import { HEADERS, HEADERS_EXCEL } from "./data";

import classes from "./TableSection.module.css";

const TableSection = React.forwardRef((props, ref) => {
  const { t, i18n } = useTranslation();
  const {
    data: rawData,
    term,
    year,
    changeSubtotal,
    subtotal,
    currency,
    isPodcast,
    isBookwire,
  } = props;
  const context = useContext(AuthContext);
  const { user } = context;
  const headersArr = HEADERS(isPodcast, isBookwire).filter((el) => el);
  const headersExcelArr = HEADERS_EXCEL(isPodcast, currency, isBookwire).filter(
    (el) => el
  );

  let sumText = "";
  let reportTerm = "";

  if (+term === 5) {
    sumText = isPodcast
      ? t(KEYS.sum_title_podcast_year, { n: year })
      : t(KEYS.sum_title_year, { n: year });
    reportTerm = t(KEYS.report_term, { n: year });
  } else if (term) {
    sumText = isPodcast
      ? t(KEYS.sum_title_podcast_quarter, { n: term })
      : t(KEYS.sum_title_quarter, { n: term });
    reportTerm = `${TERM_DATE(year, +term)} (${t(KEYS.title_quarter, {
      n: term,
    })})`;
  }

  const [tableData, setTableData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const headerItems = headersArr.map((item) =>
    item ? <th key={item}>{t(item)}</th> : null
  );
  const excelHeaderItems = headersExcelArr.map((item) =>
    item ? <th key={item}>{t(item)}</th> : null
  );

  const renderRowItems = (isLoaded, data, isPodcast) => {
    if (!isLoaded)
      return {
        items: null,
        excel: null,
      };

    if (!data || data.length === 0) {
      const emptyRow = (
        <tr>
          <td className={classes.NoData} colSpan={headersArr.length}>
            {t(KEYS.report_no_data)}
          </td>
        </tr>
      );

      return {
        items: emptyRow,
        excel: emptyRow,
      };
    }

    const rowItems = [];
    const rowItemsExcel = [];
    data.map((items) => {
      const durationText = t(KEYS.report_n_hours, {
        n: items.duration.toLocaleString(i18n.language, LOCALE_OPTIONS_0),
      });
      const usersText = t(KEYS.report_n_listener, {
        n: items.users.toLocaleString(i18n.language, LOCALE_OPTIONS_0),
      });
      const unitsText = t(KEYS.report_n_unit, {
        n: items.units.toLocaleString(i18n.language, LOCALE_OPTIONS_0),
      });
      const incomeText = `${items.income.toLocaleString(
        i18n.language,
        LOCALE_OPTIONS_2
      )} ${CURR_FORMAT(currency)}`;
      const isNewType = isPodcast || isBookwire;

      rowItems.push(
        <tr className={`${classes.TableRow} ${classes.Main}`} key={items._id}>
          {isBookwire && <td>{items.identifier}</td>}
          <td title={items.title} className={classes.Wrap}>
            {items.title}
          </td>
          {isPodcast ? <td>{items.podcast}</td> : <td>{items.author}</td>}
          {isNewType && <td>{items.publisher}</td>}
          {isNewType && <td className={classes.NoWrap}>{usersText}</td>}
          <td className={classes.NoWrap}>{durationText}</td>
          {!isNewType && <td className={classes.NoWrap}>{unitsText}</td>}
          {!isPodcast && <td className={classes.NoWrap}>{incomeText}</td>}
        </tr>
      );

      rowItemsExcel.push(
        <tr className={classes.TableRow} key={items._id}>
          {isBookwire && <td>{items.identifier}</td>}
          <td>{items.title}</td>
          {isPodcast && <td>{items.podcast}</td>}
          {!isPodcast && <td>{items.author}</td>}
          {isNewType ? (
            <td>{items.publisher}</td>
          ) : (
            <td>{user && user.name}</td>
          )}
          <td>{items.users.toFixed(2)}</td>
          <td>{items.duration.toFixed(2)}</td>
          {!isNewType && <td>{items.units.toFixed(2)}</td>}
          {!isPodcast && <td>{items.income.toFixed(2)}</td>}
        </tr>
      );

      return items;
    });

    return {
      items: rowItems,
      excel: rowItemsExcel,
    };
  };

  useEffect(() => {
    let relatedTermData = [];

    if (rawData && term && year) {
      const dataSelectedArr = rawData[year][term];
      relatedTermData = relatedTermData.concat(dataSelectedArr);

      const tableData = sumDataArr(relatedTermData).sort(
        (a, b) => b.income - a.income
      );

      setTableData(tableData);

      const subtotal = isPodcast
        ? tableData.reduce((acc, curr) => acc + curr.duration, 0)
        : tableData.reduce((acc, curr) => acc + curr.income, 0);
      changeSubtotal(subtotal);

      setIsLoaded(true);
    }
  }, [changeSubtotal, isPodcast, rawData, term, year]);

  const rows = renderRowItems(isLoaded, tableData, isPodcast);

  return (
    <section className={classes.TableSection}>
      <table className={classes.Table}>
        <thead>
          <tr className={classes.TableHead}>{headerItems}</tr>
        </thead>
        <tbody>{rows.items}</tbody>
      </table>

      {/************** excel table****************************************** */}
      <table ref={ref} className={classes.ExcelTable}>
        <thead>
          <tr className={classes.TableHead}>{excelHeaderItems}</tr>
        </thead>
        <tbody>{rows.excel}</tbody>
        <tfoot>
          <tr></tr>
          <tr className={classes.Footer}>
            <th scope="row" colSpan={headersExcelArr.length - 1} align="left">
              {term && sumText}
            </th>
            <td>{subtotal ? subtotal.toFixed(2) : 0}</td>
          </tr>
          <tr className={classes.Footer}>
            <th scope="row" align="left">
              {t(KEYS.pdf_title_report_period)}
            </th>
            <td>{reportTerm}</td>
          </tr>
          <tr className={classes.Footer}>
            <th scope="row" align="left">
              {t(KEYS.pdf_title_report_date)}
            </th>
            <td>{new Date().toLocaleDateString(i18n.language)}</td>
          </tr>
        </tfoot>
      </table>
    </section>
  );
});

export default TableSection;
