import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

import Page from "../components/shared/UI/Page";
import Stats from "../components/Dashboard/Stats";
import SelectBox from "../components/shared/UI/SelectBox";
import Graph from "../components/Dashboard/Graph";
import GraphHours from "../components/Hours/Graph";
import Options from "../components/shared/UI/Options";

import { findActiveMonths } from "../util/lib";

import sharedClasses from "./shared.module.css";
import classes from "./Dashboard.module.css";
import { KEYS } from "../util/localization/keys";

const Dashboard = (props) => {
  const { data, quarterlyData, totalData, currency, isPodcast } = props;
  const [labels, setLabels] = useState([]);
  const [stats, setStats] = useState({
    income: 0,
    duration: 0,
    users: 0,
  });
  const { ref, inView } = useInView();
  const { t } = useTranslation();

  const selectTermHandler = (e, value) => {
    const term = value || e.target.value;
    const monthsArr = findActiveMonths(term);
    setLabels(monthsArr);
  };

  useEffect(() => {
    selectTermHandler(null, 1);
  }, []);

  useEffect(() => {
    if (quarterlyData) {
      setStats(quarterlyData);
    }
  }, [quarterlyData]);

  return (
    <Page>
      <header className={classes.HeaderBox}>
        <h1 className={sharedClasses.Header}>{t(KEYS.page_title_dashboard)}</h1>
        <h2 className={sharedClasses.SubHeader}>
          {t(KEYS.description_overview)}
        </h2>
      </header>

      <Options />

      <section>
        <Stats
          isPodcast={isPodcast}
          currency={currency}
          data={stats}
          totalData={totalData || {}}
        />
        <div className={classes.Select}>
          <SelectBox selectTermHandler={selectTermHandler} />
        </div>
      </section>
      <section ref={ref} className={classes.GraphSection}>
        {data &&
          inView &&
          Object.keys(data).length > 0 &&
          (isPodcast ? (
            <GraphHours labels={labels} data={data || []} />
          ) : (
            <Graph currency={currency} labels={labels} data={data || []} />
          ))}
      </section>
    </Page>
  );
};

export default Dashboard;
