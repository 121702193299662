import { KEYS } from "../../../util/localization/keys";
import IconHome from "./icons/IconHome";
import IconListen from "./icons/IconListen";
// import IconPayment from "./icons/IconPayment";
import IconReport from "./icons/IconReport";

export const links = (isPodcast) => {
  return [
    {
      id: 1,
      title: "Genel Bakış",
      titleKey: KEYS.side_menu_title_overview,
      Icon: (props) => <IconHome {...props} />,
      to: "/dashboard",
      show: true,
    },
    {
      id: 2,
      title: "Dinleme",
      titleKey: KEYS.side_menu_title_time_report,
      Icon: (props) => <IconListen {...props} />,
      to: "/hours",
      show: !isPodcast,
    },
    {
      id: 3,
      title: isPodcast ? "Rapor" : "Gelir Raporu",
      titleKey: isPodcast
        ? KEYS.side_menu_title_podcast_report
        : KEYS.side_menu_title_report,
      Icon: (props) => <IconReport {...props} />,
      to: "/income",
      show: true,
    },
  ];
};
