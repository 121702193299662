import React, { useContext } from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../../util/context";

import iconInfo from "../../../assets/svg/icon-info.svg";
import iconDownload from "../../../assets/svg/icon-download.svg";

import classes from "./Options.module.css";
import { check } from "../../../util/lib";
import { KEYS } from "../../../util/localization/keys";

const Options = (props) => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const { quarter, year, showExcelButton } = props;

  const dataTip =
    quarter === 0 ? t(KEYS.tooltip_error_quarter) : !quarter || !year ? "" : "";
  const isDisabledLink = check(
    quarter === 0 || !quarter || !year,
    classes.Disabled
  );

  const hideContent = !quarter && !year;
  const normalUser = !auth.user.isPodcast && !auth.user.isBookwire;

  return (
    <div className={classes.Container}>
      <button onClick={auth.logout} className={classes.Link}>
        {t(KEYS.button_title_logout)}
      </button>

      {!hideContent && (
        <div className={classes.Action}>
          {normalUser && (
            <Link
              to="/Gelir_Hesaplama_ve_Raporlama-2.pdf"
              target="_blank"
              data-tip={t(KEYS.tooltip_guide)}
            >
              <img src={iconInfo} alt="info" />
            </Link>
          )}

          <ReactTooltip />

          <span data-tip={dataTip}>
            <Link
              className={`${classes.LinkContainer} ${isDisabledLink}`}
              to={`/report?year=${year}&quarter=${quarter}`}
              target={"_blank"}
            >
              <img src={iconDownload} alt="download" />{" "}
              <span className={`${classes.ButtonText}`}>
                {t(KEYS.button_title_pdf_download)}
              </span>
            </Link>
          </span>

          {showExcelButton && (
            <span data-tip={dataTip}>
              <button
                className={`${classes.LinkContainer} ${isDisabledLink}`}
                onClick={props.excelHandler}
              >
                <img src={iconDownload} alt="download" />{" "}
                <span className={`${classes.ButtonText}`}>
                  {t(KEYS.button_title_excel_download)}
                </span>
              </button>
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default Options;
