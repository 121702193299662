import React from "react";

import classes from "./Address.module.css";

const Address = (props) => {
  return (
    <address className={classes.Address}>
      <span className={classes.Header}>
        Dinlebi Sesli Kitap Yayıncılık Ticaret A.Ş.
      </span>
      Teşvikiye Mh. Akkavak Sok. No: 17/7 Şişli, İstanbul, Turkey <br />
      Vergi Dairesi: Mecidiyeköy - Vergi No: 3000504038 <br />
      <a href="https://dinlebi.io/" target={"_blank"} rel="noreferrer">
        dinlebi.io
      </a>{" "}
      {" - "}
      <a href="mailto:info@dinlebi.com.tr">info@dinlebi.com.tr</a>
    </address>
  );
};
export default Address;
