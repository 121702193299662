import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const options = {
  order: ["querystring", "localStorage"],
  lookupQuerystring: "lng",
  lookupLocalStorage: "dinlebiLng",
  caches: ["localStorage"],
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    backend: {
      loadPath: `/api/language/{{lng}}/translation.json`,
    },
    supportedLngs: ["tr", "en", "de"],
    detection: options,
    fallbackLng: "tr",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
