import React from "react";
import { useTranslation } from "react-i18next";

import NavItem from "./NavItem";

import { links } from "./data";
import logo from "../../../assets/svg/dinlebi-logo.svg";

import classes from "./Navbar.module.css";
import SelectBox from "../UI/SelectBox";
import { REGION_FORMAT } from "../../../util/lib";
import { KEYS } from "../../../util/localization/keys";

const Navbar = (props) => {
  const { t } = useTranslation();
  const { isPodcast } = props;
  const items = links(isPodcast)
    .filter((el) => el.show)
    .map((item) => (
      <NavItem key={item.id} Icon={item.Icon} to={item.to}>
        {t(item.titleKey)}
      </NavItem>
    ));

  const options = props.options.map((el) => ({
    text: REGION_FORMAT(el),
    value: el,
  }));

  return (
    <nav className={classes.Container}>
      <div>
        <div className={classes.LogoContainer}>
          <a href="https://dinlebi.io/">
            <img src={logo} alt="dinlebi-logo" />
          </a>
          <span>{t(KEYS.title_portal)}</span>
        </div>
        <ul>{items}</ul>
        {props.options[0] && (
          <div className={classes.Currency}>
            <SelectBox
              options={options}
              isLight={true}
              selectTermHandler={props.changeHandler}
              value={props.value}
            />
          </div>
        )}
      </div>

      <div className={classes.Company}>
        {props.logo && <img src={props.logo} alt="firma logosu" />}
      </div>
    </nav>
  );
};

export default React.memo(Navbar);
