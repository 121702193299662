import React from "react";

const IconReport = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0002 0H1.05351C0.806485 0.0353496 0.578581 0.182253 0.413127 0.412682C0.247673 0.64311 0.156209 0.940984 0.156209 1.24966C0.156209 1.55834 0.247673 1.85622 0.413127 2.08664C0.578581 2.31707 0.806485 2.46398 1.05351 2.49933H23.0535C23.3005 2.46398 23.5284 2.31707 23.6939 2.08664C23.8593 1.85622 23.9508 1.55834 23.9508 1.24966C23.9508 0.940984 23.8593 0.64311 23.6939 0.412682C23.5284 0.182253 23.3005 0.0353496 23.0535 0H23.0002ZM16.44 10.7586H1.05334C0.788128 10.7586 0.533769 10.8894 0.346232 11.1222C0.158696 11.355 0.0533447 11.6708 0.0533447 12C0.0533447 12.3292 0.158696 12.645 0.346232 12.8778C0.533769 13.1106 0.788128 13.2414 1.05334 13.2414H16.4934C16.7586 13.2414 17.0129 13.1106 17.2005 12.8778C17.388 12.645 17.4934 12.3292 17.4934 12C17.4934 11.6708 17.388 11.355 17.2005 11.1222C17.0129 10.8894 16.7586 10.7586 16.4934 10.7586H16.44ZM1.05334 21.5172H20.5867H20.64C20.9052 21.5172 21.1596 21.648 21.3471 21.8808C21.5347 22.1136 21.64 22.4294 21.64 22.7586C21.64 23.0879 21.5347 23.4036 21.3471 23.6364C21.1596 23.8692 20.9052 24 20.64 24H1.05334C0.788128 24 0.533769 23.8692 0.346232 23.6364C0.158696 23.4036 0.0533447 23.0879 0.0533447 22.7586C0.0533447 22.4294 0.158696 22.1136 0.346232 21.8808C0.533769 21.648 0.788128 21.5172 1.05334 21.5172Z"
      fill={props.color}
    />
  </svg>
);

export default IconReport;
