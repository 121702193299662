import { t } from "i18next";
import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import logo from "../assets/img/dinlebi-logo.png";
import Address from "../components/Report/Address";
import ReportInfoSection from "../components/Report/ReportInfoSection";
import TableSection from "../components/Report/TableSection";
import { KEYS } from "../util/localization/keys";

import classes from "./Report.module.css";

const Report = (props) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const year = +params.get("year");
  const quarter = +params.get("quarter");

  const { data, currency, isPodcast, isBookwire } = props; //quarterly data
  console.log(data);

  useEffect(() => {
    if (!quarter || !year || quarter > 5 || quarter < 1) {
      navigate("/dashboard", { replace: true });
    }
  }, [navigate, quarter, year]);

  if (!data || !quarter || !year) return null;
  if (quarter > 5 || quarter < 1) return null;

  return (
    <div className={classes.Container}>
      <div className={classes.LogoContent}>
        <img src={logo} alt="dinlebi-logo" />
        <Address />
      </div>

      <h1 className={classes.Header}>{t(KEYS.pdf_title_report)}</h1>

      <ReportInfoSection year={year} quarter={quarter} />

      <TableSection
        currency={currency}
        data={data}
        year={year}
        quarter={quarter}
        isPodcast={isPodcast}
        isBookwire={isBookwire}
      />
    </div>
  );
};

export default Report;
